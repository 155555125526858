import styled from "styled-components";

export const PaginationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 100%;
`
export const PaginationText = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: #B5B7C0;
    padding-bottom: 2px;
    @media(max-width: 840px){
        font-size: 12px;
    }
`