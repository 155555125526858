import { useState } from "react";
import { AddButton } from "../Header/Header.Styled";
import { SearchContainer, SearchImg, SearchInput } from "./Search.Styled";

type Props = {
    onClick: (text: string) => void;
}
const Search: React.FC<Props> = ({ onClick }) => {
    const [searchText, setSearchText] = useState('');
    const handleChange = (event: any) => {
            //console.log('value2', event.target.value);
            setSearchText(event.target.value);
    }
    const onKeyDown = (event: any) => {
        if (event.key === 'Enter' && event.target.value.trim() !== '') {
            //console.log('value1', event.target.value);
            setSearchText(event.target.value);
            onClick(searchText);
        }
    }
    const onBlur = (event: any) => {
        if (event.target.value === '' && searchText === '') {
            onClick(''); // возвращать прежний список
        }
    }
    const handleClick = () => {
        if (searchText !== '') {
            //console.log('value', searchText);
            onClick(searchText);
            setSearchText('');
        }
    };
    return (
        <div style={{display: 'flex'}}>
            <SearchContainer>
                <SearchInput
                    type="search"
                    id="search"
                    placeholder="Search"
                    onKeyDown={onKeyDown}
                    onChange={handleChange}
                    onBlur={onBlur}
                />
                <SearchImg src={require('../../assets/search.png')} />
            </SearchContainer>
            <AddButton style={{ width: 102 }} onClick={handleClick}>
                Search
            </AddButton>
        </div>
    )
}
export default Search;