import styled from "styled-components";

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
`
export const HeaderTitle = styled.p`
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.01em;
    color: #000000;
`
export const PlusIcon = styled.img`
    // width: 12.9px;
    height: 13px;
    padding-right: 5px;
`
export const ExcelIcon = styled.img`
    // width: 24px;
    height: 24px;
    padding-right: 5px;
    margin-left: -8px;
`
export const AddButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #3888FF;
    //width: 152px;
    padding: 0px 18px 0px 18px;
    height: 38px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
`
export const HeaderText = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: #7E7E7E;
    margin-top: 5px;
`
export const dropDownstyles = {
    control: (baseStyles, isFocused) => ({
        ...baseStyles,
        width: 146,
        height: 38,
        background: '#F5F6FA',
        borderRadius: 4,
        border: 'none',
        color: '#3D3C42',
        fontFamily: 'Lexend',
        fontWeight: 600,
        fontSize: 12,
        // marginRight: 15
    }),
    indicatorSeparator: (baseStyles, state) => ({
        ...baseStyles,
        backgroundColor: 'transparent',
    }),
    dropdownIndicator: (baseStyles, state) => ({
        ...baseStyles,
        color: '#3D3C42',
        marginLeft: -10,
        marginRight: 5
    }),
    menu: (baseStyles, state) => ({
        ...baseStyles,
        border: 'none',
        boxShadow: '0px 2px 5px 1px rgba(181, 181, 181, 0.3)',
    }),
    option: (styles, { isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isSelected ? '#F5F6FA' : 'white',
            color: '#292D32',
            fontWeight: isSelected ? 600 : 500,
            fontSize: 12,
        };
    },
}