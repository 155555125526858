import { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import { ClipLoader } from "react-spinners";
import CustomDatePickerDropdown from "../../components/CustomDatePickerSelection";
import SideMenu from "../../components/SideMenu/SideMenu";
import useWindowDimensions from "../../utils/WindowDimension";
import { Container, ContentContainer, Loader } from "../Common.Styled";
import { Block, BlockContainer, BlockText, BlockTitle, PaymentChartContainer } from "./Payment.Styled";
import first from '../../assets/payments/1.svg';
import second from '../../assets/payments/2.svg';
import third from '../../assets/payments/3.svg';
import Chart from "../../components/Chart/Chart";
import Header from "../../components/Header/Header";
import Search from "../../components/Search/Search";
import { AccountItem, AccountsContainer, AccountsTitle, AccountTitle } from "../Accounts/Accounts.Styled";
import moment from "moment";
import { PaginationContainer, PaginationText } from "../../components/Pagination/Pagination.Styled";
import customFetcher from "../../utils/fetchInstance";
import { DataPoint, PaymentType } from "../types";
import { AddButton, ExcelIcon } from "../../components/Header/Header.Styled";

function Payments() {

    type InfoType = {
        id: number,
        img: any,
        title: number,
        text: string,
        color: string
    }

    const { width } = useWindowDimensions();
    const [startDate, setStartDate] = useState<any>(null);
    const [endDate, setEndDate] = useState<any>(null);
    const [dateFilter, setDateFilter] = useState({
        start_date: undefined,
        end_date: undefined,
    });
    const [payments, setPayments] = useState<PaymentType[]>([]);
    const [searcStr, setSearcStr] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [templatesPerPage] = useState<number>(10);
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [chartData, setChartData] = useState<DataPoint[]>([]);

    const items = [{ id: 0, img: first, title: 0, text: 'Total number', color: '#FFA755' },
    { id: 1, img: second, title: 0, text: 'Stripe', color: '#68E366' },
    { id: 2, img: third, title: 0, text: 'Prodamus', color: '#812EC4' }];

    const [infoBlocks, setInfoBlocks] = useState<InfoType[]>(items);

    useEffect(() => {
        const now = new Date();
        setEndDate(now)
        const past = new Date();
        past.setDate(now.getDate() - 30);
        setStartDate(past);
    }, []);

    useEffect(() => {
        if (startDate && endDate) {
            getChart();
        }
    }, [startDate, endDate]);

    useEffect(() => {
        getPayments();
    }, [currentPage, searcStr]);

    const createChartData = (data: PaymentType[]) => {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        const formattedData: DataPoint[] = data.reduce((acc: DataPoint[], item: PaymentType) => {
            const date = new Date(item.date);
            const month = monthNames[date.getMonth()];

            let monthData = acc.find(d => d.name === month);
            if (!monthData) {
                monthData = { name: month };
                acc.push(monthData);
            }

            const providerKey = item.provider.charAt(0).toUpperCase() + item.provider.slice(1) as keyof DataPoint;
            if (providerKey === 'Stripe' || providerKey === 'Prodamus') {
                monthData[providerKey] = (monthData[providerKey] || 0) + parseFloat(item.amount);
            }

            return acc;
        }, []);

        formattedData.sort((a, b) => monthNames.indexOf(a.name) - monthNames.indexOf(b.name));

        setChartData(formattedData);
    };

    const getPayments = async () => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
            };
            const { data, response } = await customFetcher(`/api/admin/payments/list?search=${searcStr}&page=${currentPage}`, requestOptions)
            if (response.status == 200) {
                setPayments(data.rows);
                setTotalAmount(data.count);
            }
            else {
                setPayments([]);
                toast.error(data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        } catch (e) {
            console.log('get payments error', e);
        }
    }

    const handleExport = async () => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify({
                    startDate: moment(startDate).format('YYYY-MM-DD'),
                    endDate: moment(endDate).format('YYYY-MM-DD')
                })
            };
            console.log(requestOptions);

            const { response } = await customFetcher(`/api/admin/payments/export`, requestOptions);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const buffer = await response.arrayBuffer();
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            const contentDisposition = response.headers.get('Content-Disposition');
            let fileName = 'export.xlsx';
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
                if (fileNameMatch && fileNameMatch.length > 1) {
                    fileName = fileNameMatch[1];
                }
            }

            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (e) {
            console.log('handle export error', e);
        }
    };

    const getChart = async () => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify({
                    startDate: moment(startDate).format('YYYY-MM-DD'),
                    endDate: moment(endDate).format('YYYY-MM-DD')
                })
            };
            const { data, response } = await customFetcher(`/api/admin/payments`, requestOptions)
            if (response.status == 200) {
                const updatedItems = infoBlocks.map(item => {
                    if (item.id === 0) {
                        item.title = data.prodamusPaymentsCount + data.stripePaymentsCount
                    }
                    else if (item.id === 1) {
                        item.title = data.stripePaymentsCount
                    }
                    else {
                        item.title = data.prodamusPaymentsCount
                    }
                    return item;
                }
                );
                setInfoBlocks(updatedItems);
                createChartData(data.payments)
            }
            else {
                toast.error(data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        } catch (e) {
            console.log('get chart error', e);
        }
    }
    const paginate = (event: any) => {
        setCurrentPage(event.selected + 1);
    };
    const onSearch = (text: string) => {
        setCurrentPage(1);
        setSearcStr(text);
    }
    return (
        <div style={{ display: 'flex' }}>
            <SideMenu />
            <ToastContainer />
            <Container >
                <ContentContainer>
                    <div className="d-flex align-items-center justify-content-end" style={{ marginRight: -15 }}>
                        <AddButton onClick={() => handleExport()} style={{ marginRight: 15 }}>
                            <ExcelIcon src={require('../../assets/excel.png')} />
                            Export to Excel
                        </AddButton>
                        <CustomDatePickerDropdown
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            dateFilter={dateFilter}
                            setDateFilter={setDateFilter}
                        />
                    </div>
                    <PaymentChartContainer>
                        <BlockContainer>
                            {infoBlocks && infoBlocks.map((item, index) => (
                                <Block style={{ background: item.color }}>
                                    <img src={item.img} alt="" />
                                    <div>
                                        <BlockTitle>
                                            {item.title}
                                        </BlockTitle>
                                        <BlockText>
                                            {item.text}
                                        </BlockText>
                                    </div>
                                </Block>
                            ))}
                        </BlockContainer>
                        <Chart data={chartData} />
                    </PaymentChartContainer>
                    <Header title={"Payments"}
                        buttonTitle={null}
                        text={null} />
                    <Search onClick={(text: string) => onSearch(text)} />
                    <AccountsContainer>
                        <AccountsTitle>
                            <AccountTitle>
                                Payment Provider
                            </AccountTitle>
                            <AccountTitle>
                                Email
                            </AccountTitle>
                            <AccountTitle>
                                Date of Purchase
                            </AccountTitle>
                            <AccountTitle >
                                Paid Amount
                            </AccountTitle>
                        </AccountsTitle>
                        {payments ? payments?.map((item: PaymentType, index: number) => {
                            return (
                                < AccountItem key={index} >
                                    <AccountTitle style={{ color: '#292D32' }}>
                                        {item.provider.charAt(0).toUpperCase() + item.provider.slice(1)}
                                    </AccountTitle>
                                    <AccountTitle style={{ color: '#292D32' }}>
                                        {item.email}
                                    </AccountTitle>
                                    <AccountTitle style={{ color: '#292D32' }}>
                                        {moment(item.date).format("DD MMM YYYY")}
                                    </AccountTitle>
                                    <AccountTitle style={{ color: '#292D32' }}>
                                        {`${item.amount} ${item.currency}`}
                                    </AccountTitle>
                                </AccountItem>
                            )
                        }) :
                            <Loader style={{ marginTop: '22vh' }}>
                                <ClipLoader
                                    color={'#3888FF'}
                                    size={70}
                                    data-testid="loader"
                                />
                            </Loader>}
                    </AccountsContainer>
                    {payments && <PaginationContainer>
                        <PaginationText>
                            {`The total amount of templates: ${totalAmount}`}
                        </PaginationText>
                        <ReactPaginate
                            breakLabel="..."
                            onPageChange={paginate}
                            pageRangeDisplayed={1}
                            marginPagesDisplayed={2}
                            pageCount={Math.ceil(totalAmount / templatesPerPage)}
                            previousLabel={'<'}
                            nextLabel={'>'}
                            containerClassName={'pagination'}
                            breakClassName={'break-label'}
                            breakLinkClassName={'break-label-link'}
                            pageClassName={'page-number'}
                            pageLinkClassName={'page-link'}
                            previousClassName={'page-number'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-number'}
                            nextLinkClassName={'page-link'}
                            activeClassName={'active-page'}
                            activeLinkClassName={'active-page'}
                        />
                    </PaginationContainer>}
                </ContentContainer>
            </Container>
        </div >

    )
}
export default Payments;