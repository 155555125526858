import moment from 'moment';
import React from 'react';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';
import { DataPoint } from '../../pages/types';
import { ChartContainer, ChartTitle, TooltipContainer } from './Chart.Styled';
import CustomLegend from './CustomLegend';

interface CustomTooltipProps {
    active?: boolean;
    payload?: any;
    label?: string;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        const stripeData = payload.find((p: any) => p.dataKey === 'Stripe');
        const prodamusData = payload.find((p: any) => p.dataKey === 'Prodamus');

        return (
            <TooltipContainer>
                <p>{moment(label, 'MMM').format('MMM YYYY')}</p>
                {stripeData && <p>Stripe: {stripeData.value.toFixed(2)} USD</p>}
                {prodamusData && <p>Prodamus: {prodamusData.value} RUB</p>}
            </TooltipContainer>
        );
    }
    return null;
};

interface Props {
    data: DataPoint[];
}

const PaymentsAnalyticsChart: React.FC<Props> = ({ data }) => {
    return (
        <ChartContainer>
            <div className="d-flex align-items-center justify-content-between">
                <ChartTitle>Payments Analytics</ChartTitle>
                <CustomLegend />
            </div>
            <ResponsiveContainer height="94%" style={{ marginLeft: -35 }}>
                <LineChart
                    data={data}
                    margin={{
                        top: 20, right: 10, left: 10, bottom: 5,
                    }}
                >
                    <CartesianGrid stroke="#E6EDFF" strokeWidth={0.88} horizontal={true} vertical={false} />
                    <XAxis dataKey="name" stroke="#7C8DB5" />
                    <YAxis stroke="#7C8DB5" />
                    <Tooltip content={<CustomTooltip />} />
                    <Line type="monotone" dataKey="Stripe" stroke="#68E366" />
                    <Line type="monotone" dataKey="Prodamus" stroke="#812EC4" />
                </LineChart>
            </ResponsiveContainer>
        </ChartContainer>
    );
};

export default PaymentsAnalyticsChart;

