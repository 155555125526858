import styled from "styled-components";

export const SearchContainer = styled.div`
    position:relative;
    padding:0;
    margin:0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media(max-width: 1094px){
      
    }
`
export const SearchInput = styled.input`
    box-sizing: border-box;
    width: 100%;
    height: 38px;
    background: #F5F6FA;
    border-radius: 4px;
    border: none;
    padding: 0px 10px 0px 34px;
    margin-right: 15px;
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: #150035;
`
export const SearchImg = styled.img`
    position:absolute;
    bottom:12px;
    left:15px;
    width: 14px;
    height: 14px;
`