import React, { useState, useRef, useEffect } from "react";
import './sidemenu.css';
import { Link, useLocation, useNavigate } from "react-router-dom";
import useWindowDimensions from '../../utils/WindowDimension';
import useOnClickOutside from "../../utils/useOnClickOutside";
import { MenuLogo, MenuIcon, MenuMobLogo } from './SideMenu.Styled';

const SideMenu = () => {
    const { width } = useWindowDimensions();
    const [showMenu, setShowMenu] = useState(false);
    const ref = useRef();
    const location = useLocation();
    const path = location.pathname;
    const navigate = useNavigate();

    useOnClickOutside(ref, () => setShowMenu(false));
    const logOut = () => {
        localStorage.clear();
        window.dispatchEvent(new Event("storage"));
        navigate('/');
    }
    return (
        <div className={width <= 953 ? "header__container" : "side__container"}>
            {width <= 953 ?
                <div className="logo__container" >
                    <MenuMobLogo src={require('../../assets/logo1.png')} />
                    <button className="header-menu" onClick={() => { setShowMenu(!showMenu) }}>
                        <img alt="" src={require('../../assets/menu.png')} style={{ width: 45, paddingTop: 2 }} />
                    </button>
                    {showMenu && <div ref={ref} className="menu">
                        <Link to="/" className="header-menu-button"
                            style={path === "/" ? { backgroundColor: '#3888FF', color: 'white', borderBottomLeftRadius: 0, borderBottomRightRadius: 0 } : { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                            Accounts</Link>
                        <Link to="/platform" className="header-menu-button"
                            style={path === '/platform' ? { backgroundColor: '#3888FF', color: 'white', borderRadius: 0 } : { borderRadius: 0 }}>
                            Platform</Link>
                        <Link to="/localization" className="header-menu-button"
                            style={path === '/localization' ? { backgroundColor: '#3888FF', color: 'white', borderRadius: 0 } : { borderRadius: 0 }}>
                            Localization</Link>
                        <Link to="/blog" className="header-menu-button"
                            style={path === '/blog' ? { backgroundColor: '#3888FF', color: 'white', borderRadius: 0 } : { borderRadius: 0 }}>
                            Blog</Link>
                        <Link to="/blog" className="header-menu-button"
                            style={path === '/blog' ? { backgroundColor: '#3888FF', color: 'white', borderRadius: 0 } : { borderRadius: 0 }}>
                            Blog</Link>
                        <Link to="/templates" className="header-menu-button"
                            style={path === '/templates' ? { backgroundColor: '#3888FF', color: 'white', borderRadius: 0 } : { borderRadius: 0 }}>
                            CV Templates</Link>
                        <Link to="/payments" className="header-menu-button"
                            style={path === '/payments' ? { backgroundColor: '#3888FF', color: 'white', borderRadius: 0 } : { borderRadius: 0 }}>
                            Payments</Link>
                        <button className="header-menu-button" style={{
                            border: 'none',
                            background: 'none',
                            marginLeft: 15,
                        }}
                            onClick={logOut}>
                            Logout</button>
                    </div>}
                </div> :
                <div>
                    <MenuLogo src={require('../../assets/logo1.png')} />
                    <Link to="/" className="menu-button"
                        style={path === '/' ? { backgroundColor: '#3888FF', color: 'white' } : {}}>
                        <MenuIcon alt="" src={path === '/' ? require('../../assets/accW.png') : require('../../assets/acc.png')} />
                        Accounts
                    </Link>
                    <Link to="/platform" className="menu-button"
                        style={path === '/platform' ? { backgroundColor: '#3888FF', color: 'white' } : {}}>
                        <MenuIcon alt="" src={path === '/platform' ? require('../../assets/platformW.png') : require('../../assets/platform.png')} />
                        Platform
                    </Link>
                    <Link to="/localization" className="menu-button"
                        style={path === '/localization' ? { backgroundColor: '#3888FF', color: 'white' } : {}}>
                        <MenuIcon alt="" src={path === '/localization' ? require('../../assets/websiteW.png') : require('../../assets/website.png')} />
                        Localization</Link>
                    <Link to="/blog" className="menu-button"
                        style={(path === '/blog' || path.includes('/new-blog')) ? { backgroundColor: '#3888FF', color: 'white' } : {}}>
                        <MenuIcon alt="" src={(path === '/blog' || path.includes('/new-blog')) ? require('../../assets/blogW.png') : require('../../assets/blog.png')} />
                        Blog</Link>
                    <Link to="/templates" className="menu-button"
                        style={(path === '/templates' || path.includes('edit-template')) ? { backgroundColor: '#3888FF', color: 'white' } : {}}>
                        <MenuIcon alt="" src={(path === '/templates' || path.includes('edit-template')) ? require('../../assets/templatesW.png') : require('../../assets/templates.png')} />
                        CV Templates</Link>
                    <Link to="/payments" className="menu-button"
                        style={path === '/payments' ? { backgroundColor: '#3888FF', color: 'white' } : {}}>
                        <MenuIcon alt="" src={path === '/payments' ? require('../../assets/paymentW.png') : require('../../assets/payment.png')} />
                        Payments</Link>
                    <button className="menu-button" style={{
                        border: 'none',
                        background: 'none',
                        cursor: 'pointer'
                    }} onClick={logOut}>
                        <MenuIcon src={require('../../assets/logout.png')} />
                        Logout</button>
                </div>
            }
        </div>
    );
}

export default SideMenu;