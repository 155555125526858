import styled from "styled-components";

export const AccountsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 22px;
    margin-bottom: 22px;
    width: 100%;
`
export const AccountsTitle = styled.div`
    display: flex;
    padding-bottom: 17px;
    padding-top: 17px;
`
export const AccountTitle = styled.p`
    width: 23%;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: #B5B7C0;
    margin-right: 5px;
    word-break: break-word;
`
export const AccountStatusTitle = styled.p`
    width: 15%;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: #B5B7C0;
    margin-right: 5px;
    @media(min-width: 1530px){
        margin-right: 20px;
    }
    @media(max-width: 1112px){
        width: 13%;
    }
    @media(max-width: 778px){
        width: 12%;
    }
`
export const AccountItem = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 18px;
    padding-top: 18px;
    border-top: 1px solid #F5F6FA;
`
export const AccountStatus = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 118px;
    height: 30px;
    background: #3888FF;
    border-radius: 4px;
    margin-right: 15px;
    box-sizing: border-box;
    padding: 0px 9px 0px 9px;
`
export const AccountStatusText = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    @media(max-width: 1112px){
        font-size: 13px;
    }
`