import { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './DropdownSelection.css';
import calendar from '../../assets/Calendar.svg';
import minus from '../../assets/minus.svg';
import { GRID_CONTAINER, LeftInputCalendar } from './CustomInputCalendar.Styled';
import mark from "../../assets/marked.svg";
import moment from 'moment';
import useOnClickOutside from '../../utils/useOnClickOutside';

const CustomDatePickerDropdown = (props) => {
    const { setStartDate, startDate, setEndDate, endDate, setDateFilter } = props;
    const [isDropDownVisible, setIsDropdownVisible] = useState(false);
    const [template, get_template] = useState("")
    const [ref_date, set_ref_date] = useState({
        ref_start: new Date(),
        ref_end: new Date(),
    })
    useEffect(() => {
        set_ref_date({ ...ref_date, ref_end: endDate, ref_start: startDate })
    }, [endDate, startDate])
    const [index, set_index] = useState(0);
    const handleStartDate = (date) => {
        setIsDropdownVisible(true)
        setDateFilter(prev => ({ ...prev, start_date: moment(date)?.format("YYYY-MM-DD"), end_date: moment(endDate)?.format("YYYY-MM-DD") }));
        setStartDate(date);
        get_template("")
    }

    const handleEndDate = (date) => {
        setDateFilter(prev => ({ ...prev, end_date: moment(date)?.format("YYYY-MM-DD"), start_date: moment(startDate)?.format("YYYY-MM-DD") }));
        setEndDate(date);
        get_template("")
    }
    const setToday = () => {
        set_index(1)
        setDateFilter(prev => ({
            ...prev, start_date: moment(new Date())?.format("YYYY-MM-DD"),
            end_date: moment(new Date(new Date().getTime() + 86400000))?.format("YYYY-MM-DD")
        }));
        setStartDate(new Date());
        setEndDate(new Date(new Date().getTime() + 86400000));
        get_template("Today")
    }
    const setYesterday = () => {
        set_index(2)
        setDateFilter(prev => ({
            ...prev,
            start_date: moment(new Date(new Date().getTime() - 86400000))?.format("YYYY-MM-DD"),
            end_date: moment(new Date())?.format("YYYY-MM-DD")
        }));
        setStartDate(new Date(new Date().getTime() - 86400000));
        setEndDate(new Date());
        get_template("Yesterday")
    }
    const setLast = (period) => {
        switch (period) {
            case 7:
                setDateFilter(prev => ({
                    ...prev,
                    start_date: moment(new Date(new Date().getTime() - (86400000 * 7)))?.format("YYYY-MM-DD"),
                    end_date: moment(new Date(new Date().getTime() + 86400000))?.format("YYYY-MM-DD"),
                }));
                set_index(3)
                setStartDate(new Date(new Date().getTime() - (86400000 * 7)));
                setEndDate(new Date());
                get_template("Last 7 days")
                break;
            case 30:
                setDateFilter(prev => ({
                    ...prev,
                    start_date: moment(new Date(new Date().getTime() - (86400000 * 30)))?.format("YYYY-MM-DD"),
                    end_date: moment(new Date(new Date().getTime() + 86400000))?.format("YYYY-MM-DD"),
                }));
                set_index(4)
                setStartDate(new Date(new Date().getTime() - (86400000 * 30)));
                setEndDate(new Date());
                get_template("Last 30 days")
                break;
            case 90:
                setDateFilter(prev => ({
                    ...prev,
                    start_date: moment(new Date(new Date().getTime() - (86400000 * 90)))?.format("YYYY-MM-DD"),
                    end_date: moment(new Date(new Date().getTime() + 86400000))?.format("YYYY-MM-DD")
                }));
                set_index(5)
                setStartDate(new Date(new Date().getTime() - (86400000 * 90)));
                setEndDate(new Date());
                get_template("Last 90 days")
                break;
            default:
                break;
        }

    }
    let picker_ref = useRef();
    let start_ref = useRef()
    let total_time_display = `${(moment(startDate)?.format("DD.MM.YY"))} - ${(moment(endDate)?.format("DD.MM.YY"))}`
    useOnClickOutside(picker_ref, () => {
        setIsDropdownVisible(false)
    }
    );
    return (
        <div style={{
            position: "relative",
            minWidth: template?.length ? "18%" : startDate?.getTime() !== endDate?.getTime() ? "23%" : "17%",
            zIndex: "111",
            marginRight: 15
        }}>
            <div ref={picker_ref}>
                <div className='custom-dropdown'
                    id="date_picker"
                    onClick={e => { setIsDropdownVisible(!isDropDownVisible) }}
                >
                    <div className="custom-dropdown-selection"
                    >
                        <img className='calendar-icon-first' width="18px" src={calendar} />
                        <span className="date_text">
                            {template?.length ? template : startDate?.getTime() !== endDate?.getTime() ? total_time_display : "Date"}
                        </span>
                    </div>
                </div>
                {
                    isDropDownVisible ?
                        <div className='items-holder' id='items_holder'>
                            <div className='dropdown-item d-flex flex-column'>
                                <div className='d-flex justify-content-between position-relative'>
                                    <div style={{ width: "45%" }}>
                                        <div>
                                            <p>From</p>
                                        </div>
                                        <DatePicker
                                            shouldCloseOnSelect={false}
                                            onBlur={e => setIsDropdownVisible(true)}
                                            onSelect={e => setIsDropdownVisible(true)}
                                            ref={start_ref}
                                            onFocus={e => setIsDropdownVisible(true)}
                                            onClickOutside={e => setIsDropdownVisible(true)}
                                            onInputClick={() => setIsDropdownVisible(true)}
                                            onCalendarClose={() => setIsDropdownVisible(true)}
                                            id="start"
                                            className='mt-2'
                                            dateFormat="dd-MM-yyyy"
                                            selected={startDate}
                                            onChange={(date) => handleStartDate(date)}
                                            selectsStart
                                        />
                                        <img className='calendar-icon' width="18px" src={calendar} />
                                    </div>
                                    <img className='calendar_range_icon' src={minus} alt="" />
                                    <div style={{ width: "45%" }}>
                                        <div>
                                            <p>To</p>
                                        </div>
                                        <DatePicker
                                            id="end"
                                            className='mt-2'
                                            selected={endDate}
                                            dateFormat="dd-MM-yyyy"
                                            onChange={(date) => handleEndDate(date)}
                                            selectsEnd
                                        />
                                        <img className='calendar-icon' src={calendar} />
                                    </div>
                                </div>
                                <GRID_CONTAINER
                                    gtc="45% 45%"
                                    gcg="28px">
                                    <button className='btns-calendar' onClick={setToday}>
                                        Today
                                        {
                                            index == 1 &&
                                            <img src={mark} alt="" className='ms-3' />
                                        }
                                    </button>
                                    <button className='btns-calendar' onClick={setYesterday}>
                                        Yesterday
                                        {
                                            index == 2 &&
                                            <img src={mark} alt="" className='ms-3' />
                                        }
                                    </button>
                                    <button className='btns-calendar' onClick={() => setLast(7, "Last 7 days")}>
                                        Last 7 days
                                        {
                                            index == 3 &&
                                            <img src={mark} alt="" className='ms-3' />
                                        }
                                    </button>
                                    <button className='btns-calendar' onClick={() => setLast(30, "Last 30 days")}>
                                        Last 30 days
                                        {
                                            index == 4 &&
                                            <img src={mark} alt="" className='ms-3' />
                                        }
                                    </button>
                                    <button className='btns-calendar' onClick={() => setLast(90, "Last 90 days")}>
                                        Last 90 days
                                        {
                                            index == 5 &&
                                            <img src={mark} alt="" className='ms-3' />
                                        }
                                    </button>
                                </GRID_CONTAINER>
                            </div>
                        </div> : null
                }
            </div>
        </div>

    )
}

export default CustomDatePickerDropdown;