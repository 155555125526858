import React from 'react';

const CustomLegend: React.FC = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
        <svg width="7" height="7">
          <circle cx="3.5" cy="3.5" r="3.5" fill="#68E366" />
        </svg>
        <span style={{ fontSize: 10.62, fontWeight: 400, marginLeft: 5}}>Stripe</span>
      </span>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <svg width="7" height="7">
          <circle cx="3.5" cy="3.5" r="3.5" fill="#812EC4" />
        </svg>
        <span style={{ fontSize: 10.62, fontWeight: 400, marginLeft: 5}}>Prodamus</span>
      </span>
    </div>
  );
};

export default CustomLegend;
