import styled from "styled-components";

export const MenuLogo = styled.img`
    width: 180px;
    height: 39px;
    object-fit: cover;
    margin: 56px 0px 100px 56px;
    @media(max-width: 1065px){
        width: 140px;
        height: 28px;
        margin: 56px 0px 56px 30px;
    }
`
export const MenuIcon = styled.img`
    // width: 24px;
    height: 24px;
    padding-right: 15px;
`
export const MenuMobLogo = styled.img`
    width: 180px; 
    height: 39px;
    object-fit: cover;
    margin: 30px 0px 30px 15px;
`