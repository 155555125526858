import { baseUrl } from '../constants';

let originalRequest = async (url: string, config: any) => {
  url = `${baseUrl}${url}`;
  let response = await fetch(url, config);
  let data;
  if (url.includes('/api/admin/payments/export')) {
    data = response;
  } else {
    data = await response.json();
  }
  return { response, data };
}

const refreshToken = async () => {
  const refreshToken = localStorage.getItem('refresh-token');
  try {
    const myHeaders = {
      'Content-Type': 'application/json;charset=utf-8'
    };
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        refreshToken: refreshToken
      }),
    };
    const response = await fetch(`${baseUrl}/api/auth/refresh`, requestOptions);
    const data = await response.json();
    if (data.accessToken) {
      localStorage.setItem('access-token', data.accessToken);
      return data.accessToken;
    }
  } catch (e) {
    console.log('login error', e);
  }
}

let customFetcher = async (url: string, config: any) => {
  let accessToken = localStorage.getItem('access-token');
  config.headers["Authorization"] = `Bearer ${accessToken}`;
  let { response, data } = await originalRequest(url, config);

  if (response.status === 401) {
    accessToken = await refreshToken();
    config.headers["Authorization"] = `Bearer ${accessToken}`;
    let newResponse = await originalRequest(url, config);
    response = newResponse.response;
    data = newResponse.data;
  }
  return { response, data }
}
export default customFetcher;