import styled from "styled-components";

export const PaymentChartContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 30px;
    height: 300px;
`
export const BlockContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`
export const Block = styled.div`
    display: flex;
    align-items: center;
    width: 253px;
    height: 93px;
    background: #FFA755;
    border-radius: 4px;
    padding: 0 20px;
    gap: 10px;
`
export const BlockTitle = styled.p`
    font-size: 28px;
    font-weight: 600;
    line-height: 42px;
    color: white;
`
export const BlockText = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: white;
`