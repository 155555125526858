import { Toolbar } from "@mui/material";
import styled from "styled-components";

export const ChartContainer = styled.div`
    width: 100%;
    margin-left: 16px;
    border: 0.88px solid #E6EDFF;
    padding: 18px;
`
export const ChartTitle = styled.p`
    font-size: 17.69px;
    font-weight: 500;
    line-height: 26.54px;
    color: #000000;
`
export const TooltipContainer = styled.div`
    background: #fff;
    padding: 10px;
    border: 1px solid #E6EDFF;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    p:first-child {
        margin: 0;
        color: #7C8DB5;
        font-size: 12px;
    }
    p {
        margin: 0;
        color: #000;
        font-size: 12px;
        font-weight: 300;
    }
`