import styled from "styled-components";

export const LeftInputCalendar = styled.input`
    border: none;
    padding: 8px;
    padding-left: 30px;
    width: 400px;
    background: black;
    position: relative;
`
export const GRID_CONTAINER = styled.div`
    display: grid;
    grid-template-columns: ${(props) => props.gtc};
    grid-column-gap: ${(props) => props.gcg};
`